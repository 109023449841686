<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items"/>
    <div class="d-lg-flex mb-4">
      <div class="chat-leftsidebar">
        <div class="p-3 border-bottom">
          <div @click="selectUser"
               :class="{ active: selectedPatient.id === $store.state.user.id }" class="media pointer">
            <div class="align-self-center mr-3">
              <img :src="$store.state.user.image"
                   class="avatar-xs rounded-circle"
                   alt="My Profile Image"/>
            </div>
            <div class="media-body">
              <h5 class="font-size-15 mt-0 mb-1">
                {{ $store.state.user.name }}</h5>
              <p class="text-muted mb-0">
                <i class="mdi mdi-circle text-success align-middle mr-1"></i>
                Active
              </p>
            </div>
          </div>
        </div>
        <div class="chat-leftsidebar-nav">
          <b-tabs pills fill content-class="py-4" justified v-model="mode">
            <b-tab title="Tab Realtime" active>
              <template v-slot:title>
                <i class="ri-wifi-line font-size-20"></i>
                <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.realtime') }}</span>
              </template>
              <b-card-text>
                <div>
                  <h5 class="font-size-14 px-3 mb-3">{{ $t('pages.pedisteps.realtime') }}</h5>
                  <simplebar style="max-height: 345px" id="scrollElement">
                    <ul class="list-unstyled chat-list">
                      <li v-for="patient of $store.state.monitor.patients"
                          :key="patient.id"
                          @click="selectPatient(patient)"
                          :class="{ active: selectedPatient.id === patient.id }"
                      >
                        <a href="javascript: void(0);">
                          <div class="media">
                            <div
                                class="user-img align-self-center mr-3 away"
                                v-if="patient.image"
                                :class="{
                                online: `${patient.status}` === 'online',
                                away: `${patient.status}` === 'away',
                              }">
                              <img
                                  :src="`${patient.image}`"
                                  class="rounded-circle avatar-xs"
                                  alt/>
                              <span class="user-status"></span>
                            </div>
                            <div class="user-img mr-3 away" v-if="!patient.image"
                                 :class="{online: `${patient.status}` === 'online',away: `${patient.status}` === 'away'}">
                              <div class="avatar-xs align-self-center">
                                <span class="avatar-title rounded-circle bg-light text-body">{{
                                    patient.name.charAt(0)
                                  }}</span>
                              </div>
                              <span class="user-status"></span>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h5 class="text-truncate font-size-14 mb-1">
                                {{ patient.name }}
                              </h5>
                              <p class="text-truncate mb-0">
                                {{ patient.message }}
                              </p>
                            </div>
                            <div class="font-size-11">{{ patient.time }}</div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </simplebar>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Tab Gait">
              <template v-slot:title>
                <i class="ri-walk-line font-size-20"></i>
                <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.gait') }}</span>
              </template>
              <b-card-text>
                <h5 class="font-size-14 px-3 mb-3">{{ $t('pages.pedisteps.gait') }}</h5>
                <simplebar style="max-height: 345px" id="scrollElement">
                  <ul class="list-unstyled chat-list">
                    <li
                        class
                        v-for="patient of $store.state.monitor.patients"
                        :key="patient.id"
                        @click="selectPatient(patient)"
                        :class="{ active: selectedPatient.id === patient.id }"
                    >
                      <a href="javascript: void(0);">
                        <div class="media">
                          <div
                              class="user-img align-self-center mr-3 away"
                              v-if="patient.image"
                              :class="{
                                online: `${patient.status}` === 'online',
                                away: `${patient.status}` === 'away',
                              }">
                            <img
                                :src="`${patient.image}`"
                                class="rounded-circle avatar-xs"
                                alt/>
                            <span class="user-status"></span>
                          </div>
                          <div class="user-img mr-3 away" v-if="!patient.image"
                               :class="{online: `${patient.status}` === 'online',away: `${patient.status}` === 'away'}">
                            <div class="avatar-xs align-self-center">
                                <span class="avatar-title rounded-circle bg-light text-body">{{
                                    patient.name.charAt(0)
                                  }}</span>
                            </div>
                            <span class="user-status"></span>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="text-truncate font-size-14 mb-1">
                              {{ patient.name }}
                            </h5>
                            <p class="text-truncate mb-0">
                              {{ patient.message }}
                            </p>
                          </div>
                          <div class="font-size-11">{{ patient.time }}</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </simplebar>
              </b-card-text>
            </b-tab>
            <b-tab title="Tab Events">
              <template v-slot:title>
                <i class="ri-list-ordered font-size-20"></i>
                <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.eventlog') }}</span>
              </template>
              <b-card-text>
                <h5 class="font-size-14 px-3 mb-3">{{ $t('pages.pedisteps.eventlog') }}</h5>
                <simplebar style="max-height: 345px" id="scrollElement">
                  <ul class="list-unstyled chat-list">
                    <li
                        class
                        v-for="patient of $store.state.monitor.patients"
                        :key="patient.id"
                        @click="selectPatient(patient)"
                        :class="{ active: selectedPatient.id === patient.id }"
                    >
                      <a href="javascript: void(0);">
                        <div class="media">
                          <div
                              class="user-img align-self-center mr-3 away"
                              v-if="patient.image"
                              :class="{
                                online: `${patient.status}` === 'online',
                                away: `${patient.status}` === 'away',
                              }">
                            <img
                                :src="`${patient.image}`"
                                class="rounded-circle avatar-xs"
                                alt/>
                            <span class="user-status"></span>
                          </div>
                          <div class="user-img mr-3 away" v-if="!patient.image"
                               :class="{online: `${patient.status}` === 'online',away: `${patient.status}` === 'away'}">
                            <div class="avatar-xs align-self-center">
                                <span class="avatar-title rounded-circle bg-light text-body">{{
                                    patient.name.charAt(0)
                                  }}</span>
                            </div>
                            <span class="user-status"></span>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="text-truncate font-size-14 mb-1">
                              {{ patient.name }}
                            </h5>
                            <p class="text-truncate mb-0">
                              {{ patient.message }}
                            </p>
                          </div>
                          <div class="font-size-11">{{ patient.time }}</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </simplebar>
              </b-card-text>
            </b-tab>
            <b-tab title="Tab Insights">
              <template v-slot:title>
                <i class="ri-lightbulb-line font-size-20"></i>
                <span class="mt-2 d-none d-sm-block">{{ $t('pages.pedisteps.insights') }}</span>
              </template>
              <b-card-text>
                <h5 class="font-size-14 px-3 mb-3">{{ $t('pages.pedisteps.insights') }}</h5>
                <simplebar style="max-height: 345px" id="scrollElement">
                  <ul class="list-unstyled chat-list">
                    <li
                        class
                        v-for="patient of $store.state.monitor.patients"
                        :key="patient.id"
                        @click="selectPatient(patient)"
                        :class="{ active: selectedPatient.id === patient.id }"
                    >
                      <a href="javascript: void(0);">
                        <div class="media">
                          <div
                              class="user-img align-self-center mr-3 away"
                              v-if="patient.image"
                              :class="{
                                online: `${patient.status}` === 'online',
                                away: `${patient.status}` === 'away',
                              }">
                            <img
                                :src="`${patient.image}`"
                                class="rounded-circle avatar-xs"
                                alt/>
                            <span class="user-status"></span>
                          </div>
                          <div class="user-img mr-3 away" v-if="!patient.image"
                               :class="{online: `${patient.status}` === 'online',away: `${patient.status}` === 'away'}">
                            <div class="avatar-xs align-self-center">
                                <span class="avatar-title rounded-circle bg-light text-body">{{
                                    patient.name.charAt(0)
                                  }}</span>
                            </div>
                            <span class="user-status"></span>
                          </div>
                          <div class="media-body overflow-hidden">
                            <h5 class="text-truncate font-size-14 mb-1">
                              {{ patient.name }}
                            </h5>
                            <p class="text-truncate mb-0">
                              {{ patient.message }}
                            </p>
                          </div>
                          <div class="font-size-11">{{ patient.time }}</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </simplebar>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="w-100 user-chat mt-4 mt-sm-0">
        <div class="p-3 px-lg-4 user-chat-border">
          <div class="row">
            <div class="col-lg-3 col-6">
              <h5 class="font-size-15 mb-1 text-truncate">{{ selectedPatient.name }}</h5>
              <p class="text-muted text-truncate mb-0">
                <i class="mdi mdi-circle text-warning align-middle mr-1"></i>
                Away
              </p>
            </div>
            <div class="col-lg-3 col-3">
              <h5 class="font-size-15 mb-1 text-truncate">{{ stats.lastActiveDate }}</h5>
              <p class="text-muted text-truncate mb-0">
                <i class="mdi mdi-clock text-warning align-middle mr-1"></i>
                {{ $t('pages.pedisteps.lastactive') }}
              </p>
            </div>
            <div class="col-lg-3 col-3">
              <h5 class="font-size-15 mb-1 text-truncate">{{ stats.totalUsageTime }}</h5>
              <p class="text-muted text-truncate mb-0">
                <i class="mdi mdi-clock-fast text-warning align-middle mr-1"></i>
                {{ $t('pages.pedisteps.totalusagetime') }}
              </p>
            </div>
            <div class="col-lg-2 col-3">
              <h5 class="font-size-15 mb-1 text-truncate">{{ stats.totalEvents }}</h5>
              <p class="text-muted text-truncate mb-0">
                <i class="mdi mdi-alert text-warning align-middle mr-1"></i>
                {{ $t('pages.pedisteps.totalevents') }}
              </p>
            </div>
            <div class="col-lg-1 col-3">
              <ul class="list-inline user-chat-nav text-right mb-0">
                <li class="list-inline-item d-inline-block d-sm-none">
                  <div class="dropdown">
                    <button class="btn nav-btn dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </li>
                <li class="list-inline-item d-none d-sm-inline-block">
                  <b-dropdown toggle-class="nav-btn" right variant="white">
                    <template v-slot:button-content>
                      <i class="mdi mdi-cog"></i>
                    </template>
                    <b-dropdown-item>View Profile</b-dropdown-item>
                    <b-dropdown-item>Clear chat</b-dropdown-item>
                    <b-dropdown-item>Muted</b-dropdown-item>
                    <b-dropdown-item>Delete</b-dropdown-item>
                  </b-dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="p-lg-4 p-2">
          <div class="pedisteps-heatmap" v-if="mode<2">
            <div class="steps-container">
              <div class="steps-left">
                <i :key="`sl-${i}`"
                   :style="{left: `${s.x-10}px`, top: `${s.y}px`, 'box-shadow':`0 0 30px 5px ${heatRgba(valL[i])}`, background: heatRgba(valL[i]) }"
                   v-for="(s,i) in pedisol40"></i>
              </div>
              <div class="steps-center"></div>
              <div class="steps-right">
                <i :key="`sl-${i}`"
                   :style="{right: `${s.x-10}px`, top: `${s.y}px`, 'box-shadow':`0 0 30px 5px ${heatRgba(valR[i])}`, background: heatRgba(valR[i]) }"
                   v-for="(s,i) in pedisol40"></i>
              </div>
            </div>
          </div>
          <div class="pedisteps-events" v-if="mode === 2">
            <ul v-if="mappedEvents.length" class="list-group">
              <li v-for="event in mappedEvents" :key="event.timestamp"
                  class="list-group-item d-flex justify-content-between align-items-center">
                <span class="badge" :class="event.labelClass">{{ event.labelText }}</span>
                <span class="event-description" v-html="event.description"></span>
                <span class="event-time">{{ event.timestamp }}</span>
              </li>
            </ul>
            <p v-else class="text-muted">No events found for this user.</p>
          </div>
          <div class="pedisteps-insights" v-if="mode === 3">
            <p>No insights available at the moment.</p>
          </div>
        </div>

      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
<script>
import simplebar from 'simplebar-vue'
// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
// eslint-disable-next-line no-unused-vars
import {collection, getFirestore, onSnapshot, query, where, orderBy, limit} from 'firebase/firestore'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'

import {pedisol30Data, pedisol40Data} from './data'

import Background from '@/assets/images/shoe-prints.svg'
import {required} from 'vuelidate/lib/validators'

import {monitorMethods, userMethods} from '@/state/helpers'
import {getApi} from '@/api'

const firebaseConfig = {
  apiKey: 'AIzaSyDY20ZKArG6BDHunz_Hr9HSbIOikxemZ34',
  authDomain: 'vrsteps-3866d.firebaseapp.com',
  databaseURL: 'https://vrsteps-3866d.firebaseio.com',
  projectId: 'vrsteps-3866d',
  storageBucket: 'vrsteps-3866d.appspot.com',
  messagingSenderId: '551929963690',
  appId: '1:551929963690:web:7190bead804cb7a4505cd3',
  measurementId: 'G-KZE3TMDG9R'
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firestore
const db = getFirestore(app)
/**
 * Pedisteps Component
 */
export default {
  page: {
    title: 'Pedisteps Gait',
    meta: [{name: 'description'}],
  },
  components: {
    Layout,
    PageHeader,
    simplebar,
  },
  data() {
    return {
      events: [],
      form: {
        message: '',
      },
      insights: [],
      items: [
        {
          text: 'VRsteps',
          href: '/',
        },
        {
          text: 'Pedisteps',
          active: true,
        },
      ],
      L: [],
      mode: 0,
      modes: {REALTIME: 0, GAIT: 1, EVENTS: 2, INSIGHTS: 3},
      pedisol30: pedisol30Data,
      pedisol40: pedisol40Data,
      pedisolSize: 40,
      realtimeBackground: Background,
      R: [],
      rtData: [],
      selectedPatient: {
        id: null,
        name: '',
        image: '',
        activation_token: ''
      },
      stats: {
        lastActiveDate: '2024-08-30',
        totalUsageTime: '0h 15m',
        totalEvents: '0'
      },
      submitted: false,
      title: 'pages.pedisteps.title',
    }

  },
  validations: {
    form: {
      message: {required},
    },
  },
  methods: {
    ...userMethods,
    ...monitorMethods,
    ...getApi(),

    selectUser() {
      this.selectedPatient = this.$store.state.user;
      console.log(this.selectedPatient)
    },

    selectPatient(patient) {
      this.selectedPatient = patient
    },

    heatRgba(pressure) {
      if (pressure < 0 || pressure > 255) return 'rgba(255, 255, 255, 0.9)'
      if (pressure === 0) return 'rgba(255, 255, 255, 0.25)' // White

      const blueRangeEnd = 100
      const greenRangeEnd = 150
      const yellowRangeEnd = 200
      let r, g, b

      if (pressure <= blueRangeEnd) {
        // Transition from white to blue
        r = g = Math.max(255 - pressure * 2.55, 0)
        b = 255
      } else if (pressure <= greenRangeEnd) {
        // Transition from blue to green
        const factor = (pressure - blueRangeEnd) / (greenRangeEnd - blueRangeEnd)
        r = 0
        g = 255
        b = 255 - factor * 255
      } else if (pressure <= yellowRangeEnd) {
        // Transition from green to yellow
        const factor = (pressure - greenRangeEnd) / (yellowRangeEnd - greenRangeEnd)
        r = factor * 255
        g = 255
        b = 0
      } else {
        // Transition from yellow to red
        const factor = (pressure - yellowRangeEnd) / (255 - yellowRangeEnd)
        r = 255
        g = 255 - factor * 255
        b = 0
      }

      return `rgba(${r}, ${g}, ${b}, 0.25)`
    },

    loadRealtime() {
      // eslint-disable-next-line no-unused-vars
      const twentySecondsAgo = new Date(Date.now() - 20 * 1000) // 20 seconds ago
      const q = query(collection(db, 'pedisol'),
          orderBy('T', 'desc'), // Make sure documents are ordered by createdAt
          where('T', '>=', twentySecondsAgo),
          where('Token', '==', this.selectedPatient.activation_token),
          limit(1000)
      ) // Adjust if you want to limit the number of documents

      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const docData = change.doc.data()
          const changeType = change.type

          if (changeType === 'added') {
            this.L = docData.L
            this.R = docData.R
          }
        })

      }, (error) => {
        console.error('Error listening to collection changes: ', error)
      })
    },

    loadGait() {
      const q = query(collection(db, 'pedisol'),
          orderBy('T', 'desc'), // Make sure documents are ordered by createdAt
          where('Token', '==', this.selectedPatient.activation_token),
          limit(1000)
      ) // Adjust if you want to limit the number of documents

      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const docData = change.doc.data()
          const changeType = change.type

          if (changeType === 'added') {
            this.L = docData.L
            this.R = docData.R
          }
        })

      }, (error) => {
        console.error('Error listening to collection changes: ', error)
      })
    },

    loadEvents() {
      this.events = [];
      const q = query(
          collection(db, 'events'),
          where('token', '==', this.selectedPatient.activation_token),
          orderBy('t', 'desc'),
          limit(1000)
      );

      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const e = change.doc.data();
          const changeType = change.type;

          if (changeType === 'added') {
            this.events.push(e);
          }
        });
      }, (error) => {
        console.error('Error listening to collection changes: ', error);
      });
    },

    loadInsights() {

    },

    formatTimestamp(timestamp) {
      return new Date(timestamp * 1000).toLocaleString(); // Convert Unix timestamp to human-readable date
    },

    formatTechnicalEvent(event) {
      const leftColor = event.L ? 'text-success' : 'text-danger'; // Green for true, red for false
      const rightColor = event.R ? 'text-success' : 'text-danger';
      return 'Insoles state: ' + `<span class="${leftColor}">●</span> ` + this.$t('general.left') + `, <span class="${rightColor}">●</span> ` + this.$t('general.right');
    },

    formatMedicalEvent(event) {
      return `Weight Bearing ${event.bearing_pct}% for ${event.bearing_dur}s`;
    }

  },
  watch: {

    mode(newMode) {

      if (this.unsubscribe) {
        this.unsubscribe()
      }

      switch (newMode) {
        case 0:
          this.loadRealtime();
          break;
        case 1:
          this.loadGait();
          break;
        case 2:
          this.loadEvents();
          break;
        case 3:
          this.loadInsights();
          break;
      }
    },
  },
  computed: {
    mappedEvents() {
      return this.events.map(e => {
        let labelClass = '';
        let labelText = '';
        let description = '';
        let timestamp = this.formatTimestamp(e.t);

        switch (e.type) {
          case 1:
            labelClass = 'badge-warning'; // Yellow for technical events
            labelText = 'Technical';
            description = this.formatTechnicalEvent(e.event);
            break;
          case 101:
            labelClass = 'badge-danger'; // Red for medical events
            labelText = 'Medical';
            description = this.formatMedicalEvent(e.event);
            break;
          default:
            labelClass = 'badge-secondary'; // Grey for unknown events
            labelText = 'Unknown Event';
            description = 'No description available.';
            break;
        }

        return {
          labelClass,
          labelText,
          timestamp,
          description
        };
      });
    },

    messages() {
      return this.$store.state.monitor.messages.filter(m => m.from_id === this.selectedPatient.id || m.to_id === this.selectedPatient.id).sort((a, b) => {
        return a.send_date < b.send_date ? -1 : 1
      })
    },
    sensors() {
      return this.pedisolSize === 30 ? [] : []
    },
    valL() {
      if (this.L.length !== this.pedisolSize) return new Array(this.pedisolSize).fill(0)
      return this.L
    },
    valR() {
      if (this.R.length !== this.pedisolSize) return new Array(this.pedisolSize).fill(0)
      return this.R
    }
  },
  mounted() {
    const userId = this.$store.state.user.id
    const accessToken = this.$store.state.user.access_token

    this.selectUser();

    this.getPatients(userId, accessToken).then(response => response.json()).then(
        (results) => {
          this.setPatients({patients: results.patients})
        }
    )
  },
  beforeDestroy() {
    // Stop listening to changes
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
}
</script>